
export default {
    name: 'SearchList',
    components: {},
    asyncData({ api, cookies, ...context }) { },
    props: {
        header: Object,
        info: Object
    },
    data() {
        return {
            skeleton: true,
            allData: {},
            listCompo: {     // 列表组件配置
                list: [],
                options: {
                    hasMore: true,
                    hasConcat: true,
                    columns: 2,
                    observeTag: '.SearchList--observe',
                    hasRate: true,
                }
            },
            stickyComo: {
                hasNav: true,
                hasSort: true,
                hasFilter: true,
                hasCloseSticky: true,
                stickyTag: '.SearchHead-section',
                data: {}
            },
            filter: {         // 筛选结果
                num: 0,
                mergeSort: '',
                params: {}
            },
            rec: {
                visible: false,
                list: [],
                options: {
                    hasMore: true,
                    hasConcat: true,
                    columns: 2,
                    observeTag: '.SearchList-rec--observe',
                }
            },
            page: {
                pageSize: 20,
                pageNum: 1,
                totalPage: 1,
                total: 0
            }
        }
    },
    computed: {},
    watch: {
        info: {
            handler() {
                this.setData(this.info || {})
            },
            deep: true,
            immediate: true
        }
    },
    created() { },
    mounted() {
        // this.initialSet()
        // this.getSearchListAjax(false)
    },
    methods: {
        // 获取搜索列表
        getSearchListAjax(bool = false) {
            this.$api.search.getSearchList({
                pageSize: this.page.pageSize,
                pageNum: this.page.pageNum,
                keyword: this.header.keyword,
                sortColumn: this.filter.mergeSort,
                needFilter: true,
                ...this.filter.params,
            }, false).then(response => {
                if (response.code != 'success') return
                const res = response.result || {};
                this.allData = res
                this.setData()
                this.$fnApi.buried.gtag({
                    type: 'search',
                    params:{
                        search_term: this.header.keyword
                    }
                }, this)
            })
        },
        setData(obj) {
            const res = obj || this.allData
            // 筛选
            this.stickyComo.data = res.filter || {}
            this.stickyComo.data.total = res.total

            this.listCompo.list = res.list || []
            this.page.totalPage = res.totalPage
            this.page.total = res.total
            this.skeleton = false
            this.rec.visible = !this.listCompo.list.length
            if(process.client) {
                this.rec.visible && this.getRecAjax()
                this.setSearchLogAjax()
            }
        },
        getRecAjax() {
            this.$api.common.getRecV5Common({
                index: 5,
                pageSize: this.page.pageSize,
                pageNum: this.page.pageNum
            }, false).then(response => {
                const res = response.result || {};
                this.rec.list = res.list || []
                this.page.totalPage = res.totalPage
            })
        },
        // 搜索词变更
        changeSearch() {
            this.page.pageNum = 1
            // this.stickyComo.hasReset = true
            this.listCompo.options.hasConcat = false
            this.rec.options.hasConcat = false
            this.getSearchListAjax()
        },
        // 滚动至顶部
        setScrollTo() {
            this.$VueScrollTo.scrollTo('body')
        },
        // 搜索日志
        setSearchLogAjax() {
            this.$api.search.setSearchLog({
                searchWords: this.header.keyword,
                total: this.page.total
            }).then(res => {
            })
        },
        // 筛选
        emitChangeSort(obj) {
            this.listCompo.options.columns = obj.options.columns
        },
        emitChangeFilter(obj) { // filter change
            this.filter.mergeSort = obj.options.mergeSort
            this.filter.params = obj.filterResult.params
            this.listCompo.options.hasConcat = false
            this.rec.options.hasConcat = false
            this.page.pageNum = 1
            this.getSearchListAjax()
            this.setScrollTo()
        },
        emitBottomLoadingList(e, callBack) {
            let bool = false;
            !this.rec.visible && (bool = this.emitBottomLoading(e))
            callBack(bool)
        },
        emitBottomLoadingRec(e, callBack) {
            let bool = false;
            this.rec.visible && (bool = this.emitBottomLoading(e))
            callBack(bool)
        },
        // 触底加载
        emitBottomLoading(e) {
            const hasNext = this.page.totalPage > this.page.pageNum;
            if (e.isIntersecting && hasNext) {
                this.$fnApi.debounce(() => {
                    this.page.pageNum += 1
                    if (!this.rec.visible) {
                        this.getSearchListAjax(true)
                        this.listCompo.options.hasConcat = true
                    } else {
                        this.rec.options.hasConcat = true
                        this.getRecAjax()
                    }
                }, 200)
            }
            return !hasNext
        },
        // 初始化
        initialSet() {
            // const language = this.$storage.get('language');
            // if(['en_US'].includes(language.identify)) {
            //     this.stickyComo.hasLayout = true
            //     this.stickyComo.hasFilter = true
            // }
        }
    },
}
